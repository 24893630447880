<template>
  <div>
    <el-row>
      <el-col :span="24" class="infoTit">
        <span  v-if="goodsinfo.otcType===1 && goodsinfo.drugCategory==0"  class="tag bgRed titColor radiusBox">OTC</span>
        <span  v-if="goodsinfo.otcType===1 && goodsinfo.drugCategory==1"  class="tag bgGreen titColor radiusBox">OTC</span>
        <span  v-if="goodsinfo.otcType===0"  class="tag bgRed titColor">Rx</span>
        <span  v-if="goodsinfo.newStatus==true||goodsinfo.newStatus=='true'"  class="tag bgGreen titColor">新品</span>
        <span  v-if="goodsinfo.medicalInsuranceType>0"  class="tag bgBlue titColor">医保</span>
        <span  v-if="goodsinfo.proprietary==1"   class="tag bgOrange titColor">自营</span>
        <span v-if="goodsinfo.isReview == 1" class="tag zsStyle">追溯码</span>
         <!-- {{ goodsinfo.name }} -->
         <span v-if="goodsinfo.alias==''||goodsinfo.alias==null">{{ goodsinfo.name }}  </span>
         <span v-else>{{ goodsinfo.alias }}  </span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="infoMsg">
        <span>{{ goodsinfo.subTitle }}</span>
      </el-col>
    </el-row>

    <div  class="UlBoxs">
        <span class="liBox">
          <span class="infoKeyPrice">会员价：</span>
          <!-- Number(JSON.parse(goodsinfo.numPrice)[JSON.parse(goodsinfo.numPrice).length - 1].price).toFixed(2) }}起 -->
          <span class="price"  v-if="goodsinfo.numPrice && goodsinfo.numPrice!=''"><strong>￥</strong>{{ goodsinfo.price==="***"?"会员可见":priceMsg}}</span>
          <span class="price"  v-else><strong>￥</strong>{{ goodsinfo.price==="***"?"会员可见":goodsinfo.price }}</span>
          <del  v-if="goodsinfo.numPrice && goodsinfo.numPrice!=''">{{goodsinfo.price!=="***"?Number(goodsinfo.price)>=Number(goodsinfo.memberPrice)?'￥'+Number(goodsinfo.price):goodsinfo.memberPrice:''}}</del>
        </span>
        <el-divider direction="vertical"></el-divider>
        <span  class="liBox">
          <span class="infoKey">推荐零售价：</span>
          ￥{{goodsinfo.price==="***"?"会员可见":goodsinfo.originalPrice}}
        </span>
        <el-divider direction="vertical"></el-divider>
        <span  class="liBox">
          <span class="infoKey">毛利率：</span>{{ goodsinfo.grossProfitMargin }}%
        </span>
        <el-divider direction="vertical"></el-divider>
        <span  class="liBox">
          <span class="infoKey">净赚：</span>￥{{ goodsinfo.price==="***"?"会员可见":goodsinfo.earnings }}
        </span>
    </div>


    <el-row class="infoBox"  v-if="ByGoodsFlag || promotionMap.length>0">
      <div v-if="ByGoodsFlag" class="hotProductTagBox" @click="hotProductFun">
        <span class="hotProductTag">{{ByGoodsTag}} <b><img src="@/assets/hotProductTag.png" alt=""></b></span>
      </div>
      <!-- <el-col :span="8">
        <span class="infoKey">会员价: </span
        ><span class="price"><strong>￥</strong>{{ goodsinfo.price==="***"?"会员可见":goodsinfo.price }}</span>
        <del>{{goodsinfo.price!=="***"?Number(goodsinfo.price)>=Number(goodsinfo.memberPrice)?'':goodsinfo.memberPrice:''}}</del>
      </el-col>
      <el-col :span="6">
        <span class="infoKey">推荐零售价: </span>￥{{
          goodsinfo.price==="***"?"会员可见":goodsinfo.originalPrice
        }}</el-col
      >
      <el-col :span="5"><span class="infoKey">毛利率: </span>{{ goodsinfo.grossProfitMargin }}%</el-col>
      <el-col :span="5"><span class="infoKey">净赚: </span>￥{{ goodsinfo.price==="***"?"会员可见":goodsinfo.earnings }}</el-col> -->
      <div v-if="promotionMap.length==1 && promotionMap[0].type==='特价'">
      </div>
      <div v-else>
        <el-col :span="24" class="infoActivity"  v-for="(item,index) in promotionMap" :key="index">
          <span class="infoKey"  v-if="index==0">促销活动:</span>
          <span class="noMsg" v-else></span>
            <div class="ActivityDesBox">
              <span class="infoTag" v-if="item.type=='一元购'" @click="JumpONE_BUYFun(item.id)">{{item.type}}</span>
              <span class="infoTag" v-else-if="item.type=='限时拼团'"   @click="JumpFlashGroupFun(item.id)">{{item.type}}</span>
              <span class="infoTag" v-else-if="item.type=='限时抢购'"   @click="JumpFlashSaleFun(item.id)">{{item.type}}</span>
              <span  class="noMsg"  v-else-if="item.type=='特价'"></span>
              <span class="infoTag" v-else>{{item.type}}</span>

              <span  class="noMsg"  v-if="item.type=='特价'" ></span>
              <span v-else class="infoDes">{{item.name}}</span>
            </div>
        </el-col>
      </div>
      <el-col :span="24" class="infoActivitys" v-if="goodsinfo.numPrice && goodsinfo.numPrice!=''">
        <span  v-if="promotionMap.length<1 " class="infoKey">促销活动:</span>
        <span  v-else class="infoKey"></span>
        <span class="infoTag">特价活动</span>
        <p class="numPriceDes" v-for="(item,index) in JSON.parse(goodsinfo.numPrice)" :key="index">
          满{{item.num}}盒<span v-if="item.ratio">享{{(item.ratio)/10}}折,</span> <span v-if="!item.ratio">享特价{{ item.price }}元，</span>每盒约省<b>{{Number(goodsinfo.memberPrice-item.price).toFixed(2)}}</b>元
          <!-- <span v-if="index<JSON.parse(goodsinfo.numPrice).length-1">,</span>   -->
        </p>
      </el-col>


      <!-- <el-col v-if="ByGoodsFlag" :span="24" class="infoActivity" @click.native="hotProductFun">
        <span class="hotProductTag">{{ByGoodsTag}} <b><img src="@/assets/hotProductTag.png" alt=""></b></span>
      </el-col> -->
      <!-- <el-col :span="24" class="infoActivity">
        <span class="infoKey"></span>
        <span class="infoTag">免运费</span>
        <span class="infoDes">该商品免运费</span>
      </el-col> -->
    </el-row>

    <!-- 优惠券  +  优惠券侧弹窗 -->
    <el-row  :span="24"  class="CouponBox" v-if="goodsinfo.coupons.length > 0">
      <div>
        <span class="infoKey">优惠券:</span>
        <span class="conponMsg"  v-for="(item,index) in goodsinfo.coupons.slice(0,3)" :key="index" @click="drawer = true" type="primary" >{{item.couponName}}</span>
      </div>
    </el-row>
<el-drawer
  title="优惠券"
  :with-header="false"
  :visible.sync="drawer"   
  direction="rtl"
  :before-close="handleClose">
     <div class="headTit">优惠券</div>
     <div class="conMain">
       <div>
          <GetCouponItem v-for='(item,index) in goodsinfo.coupons' :key="index" :span="24" :couponItem="item"></GetCouponItem>
       </div>
     </div>
</el-drawer>


    <el-row class="infoParameter">
      <div><span class="infoKeys">商品编号:</span><span>{{goodsinfo.productSn }}</span></div>
       <div><span class="infoKeys">国药准字:</span><span>{{goodsinfo.approvalNo}}</span></div>
      <div><span class="infoKeys">药品规格:</span><span>{{goodsinfo.specification}}</span></div>
      <div><span class="infoKeys">件装规格:</span><span>{{goodsinfo.partSpecification}}</span></div>
      <div><span class="infoKeys">生产日期:</span><span>{{goodsinfo.producedDateFromat}}</span></div>
      <div><span class="infoKeys">效期优于:</span><span>{{goodsinfo.validDateFromat}}</span></div>
      <div><span class="infoKeys">医保类型:</span><span>{{goodsinfo.medicalInsuranceType===0?'非医保':goodsinfo.medicalInsuranceType===1?'甲类医保':'乙类医保'}}</span></div>
      <div>
        <!-- <span class="infoKeys" style="text-indent: 30px;">库存:</span> -->
        <span class="infoKeys" style="text-indent:10px;letter-spacing:9px; ">库存:</span>
        <span v-if="goodsinfo.quantity==-1 || goodsinfo.quantity< Number(goodsinfo.minOrderNum)">备货中</span>
        <span v-if="goodsinfo.quantity>1000">充足</span>
        <span v-if="goodsinfo.quantity<1000 && goodsinfo.quantity> Number(goodsinfo.minOrderNum)">{{goodsinfo.quantity+goodsinfo.unit}}</span>
      </div>
      <div v-if="goodsinfo.quantity==-1 && goodsinfo.deliveryTimeFormat"><span class="infoKeys">到货日期:</span><span>{{goodsinfo.deliveryTimeFormat}}</span></div>
      <div  @click="Download(goodsinfo.goodNum)"> <span class="infoKeys" >首营资料：</span> <strong class="downBtn">查看/下载</strong></div>
    </el-row>

    <el-row class="infoActivitysBox" v-if="goodsinfo.numPrice && goodsinfo.numPrice!=''">
      <span class="infoKey">特价活动</span>
      <el-col :span="24" class="infoActivitys" >
          <div  v-for="(item,index) in numPriceArr" :key="index" @click="checkFun(item,index)"  :class="keyNum!==index?'numPriceDesBtn':'numPriceDesBtn active'">
            <p>￥{{ item.price }}</p>
            <p>{{item.num}}盒起</p>
          </div>
      </el-col>
    </el-row>
    <el-row class="infoNumBox">
      <el-col :span="24">
        <span class="infoKey">采购数量:</span>
        <!-- :max="Number(goodsinfo.quantity)" -->
        <el-input-number
          v-model="num"
          @change="handleChange"
          :min="goodsinfo.minOrderNum"
          :max="goodsinfo.isLimit==0?Number(goodsinfo.quantity):Number(goodsinfo.quantity)>Number(goodsinfo.purchaseLimitationNum)?Number(goodsinfo.purchaseLimitationNum):Number(goodsinfo.quantity)"
          :step="goodsinfo.minOrderNum"
          step-strictly
        ></el-input-number>

        <span v-if="goodsinfo.isLimit==1">
          <span class="desPro" v-if="goodsinfo.purchaseLimitationNum>0">*截止{{goodsinfo.purchaseLimitationEndTime.substring(0,10)}}之前，您还可以购买{{goodsinfo.purchaseLimitationNum}}盒</span>
          <span class="desPro" v-else>已达购买上限，暂无法下单</span>
        </span>
        <span class="desPro">{{ NowPriceMSG }}</span>
      </el-col>
    </el-row>
    <div class="infoBtns"  v-if="goodsinfo.isLimit==1">
      <div :span="24">
        <span class="infoKey"></span>

        <!-- <button :disabled="goodsinfo.quantity< Number(goodsinfo.minOrderNum)" :class="goodsinfo.quantity< Number(goodsinfo.minOrderNum)?'grey shopBtn':'shopBtn'" @click="immediateBuy(goodsinfo)">立即购买</button> -->
        <button :disabled="Number(goodsinfo.purchaseLimitationNum) < Number(goodsinfo.minOrderNum)" :class="Number(goodsinfo.purchaseLimitationNum) < Number(goodsinfo.minOrderNum)?'grey addBtn':'addBtn'" @click="addCartFun(goodsinfo)">
          加入购物车
        </button>
      </div>
    </div>
    <div class="infoBtns"  v-else>
      <div :span="24">
        <span class="infoKey"></span>

        <!-- <button :disabled="goodsinfo.quantity< Number(goodsinfo.minOrderNum)" :class="goodsinfo.quantity< Number(goodsinfo.minOrderNum)?'grey shopBtn':'shopBtn'" @click="immediateBuy(goodsinfo)">立即购买</button> -->
        <button :disabled="goodsinfo.quantity< Number(goodsinfo.minOrderNum)" :class="goodsinfo.quantity< Number(goodsinfo.minOrderNum)?'grey addBtn':'addBtn'" @click="addCartFun(goodsinfo)">
          <span v-if="goodsinfo.quantity< Number(goodsinfo.minOrderNum)">补货中</span>
          <span v-else>加入购物车</span>
        </button>
      </div>
    </div>
    <!-- <el-col :span="24">
      <el-col :span="4"
        ><div class="grid-content bg-purple">
          服务承诺：<i class="el-icon-circle-check" aria-hidden="true"></i></div
      ></el-col>
      <el-col :span="4"
        ><div class="grid-content bg-purple-light">
          正品保障<i class="el-icon-circle-check" aria-hidden="true"></i></div
      ></el-col>
      <el-col :span="8"
        ><div class="grid-content bg-purple">
          满1000免运费【新疆除外】<i
            class="el-icon-circle-check"
            aria-hidden="true"
          ></i></div
      ></el-col>
      <el-col :span="4"
        ><div class="grid-content bg-purple-light">药监认证</div></el-col
      >
    </el-col> -->
  </div>
</template>
<style lang="less" scoped>
@import "~style/index.less";
.info {
  overflow: hidden;
  .infoTit {
    font-size: 20px;
    line-height: 30px;
    color: #333;
    font-weight: bold;
    .tag{
          height: 20px;
          padding: 0 5px;
          line-height: 19px;
          margin-top: 2px;
          border-width: 1px;
          font-size:14px;
          border-style: solid;
          box-sizing: border-box;
          white-space: nowrap;
          display: inline;
          margin-right:3px;
          &.zsStyle{
            line-height:20px;
            border: 1px solid #409eff;
            color: #409eff;
              background-color:  #eff7ff;

          }
          &.titColor{
            color:#fff
          }
           &.radiusBox{
            border-radius:5px;
          }
          &.bgRed{
             background-color: #DD1F1F;
              border-color: #DD1F1F;
          }
          &.bgBlue{
              background-color: #409eff;
              border-color: #409eff;
          }
          &.bgGreen{
            background-color:  #52C41A;
              border-color:  #52C41A;
          }
          &.bgOrange{
             background-color:#FF7A45;
               border-color: #FF7A45;
          }
    }
  }
  .infoMsg {
    color: #666;
    font-size: 14px;
    line-height:24px;
    margin-top: 5px;

  }
  // span.infoKey {
  //   // width: 86px;
  //   text-align: left;
  //   display: inline-block;
  //   color: #333333 ;
  //   font-size: 14px;
  // }
  // span.infoKeyLabel{
  //   width:72px;
  //   text-align: left;
  //   display: inline-block;
  //   color: #333333 ;
  //   font-size: 14px;
  // }
  .downBtn{
    cursor: pointer;
    background: #0EA0FF;
    color:#fff;
    padding:2px 5px;
    &:hover{
         background: #ff3333;
    }
  }
  .infoNumBox {
    padding: 0px 10px;
    margin-bottom: 25px;
    span.infoKey {
      width: 86px;
      text-align: left;
      display: inline-block;
      color: #333;
      font-size: 14px;
    }
    .desPro{
      margin-left: 15px;
      color: #FA7816FF;
      font-size: 13px;
    }
  }
  .infoBtns {
    padding: 0px 10px;
    z-index: 900;
    position: relative;
    margin-bottom: 20px;
    button {
      width: 150px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      font-size: 15px;
      font-weight: bold;
      margin-top: 8px;
      border-radius: 0;
      padding: 0;
      &.addBtn {
        background: @theme;
        color: #fff;
        margin-left: 20px;
        border: 1px solid @theme;
        outline: none;
      }
      &.shopBtn {
        background: @shopBtnTheme;
        color: @theme;
        border: 1px solid @theme;
        outline: none;
      }
      &.grey{
        background: #ababb0;
          color: #fff;
          border: 1px solid #ababb0;
      }
    }
  }
  // 优惠券 start
  .CouponBox {
    padding: 0px 10px 5px;
    margin-bottom:10px;
    color: #ff3333;
    .infoKey{
        width: 86px;
        text-align: left;
        display: inline-block;
        color: #333;
        font-size: 14px;
      }
    div{
      float: left;
      width:100%;
       .conponMsg{
         font-size: 14px;
         padding: 2px 5px;
         border: 1px solid #ff3333;
         border-radius: 4px;
         margin-right: 10px;
         font-weight: bold;
         cursor: pointer;
         &:hover{
            background: #f6f6f6;
            border: 2px solid #ff3333;
         }
       }
    }
  }

  // 重置优惠券组件样式，提高权重
  /deep/.coupon{
    width:370px;
  }
  /deep/.el-drawer{
    width: 420px!important;
    padding:0 20px 20px 20px;
  }
  .headTit{
       margin-top: 15px;
    line-height: 40px;
    margin-bottom: 6px;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }
  .conMain{
    max-height:830px;
        overflow-y: scroll;
    overflow-x: hidden;
  }
   ::-webkit-scrollbar {
    width: 2px;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    border-radius: 1px;
    background-color: #fff;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
  }
  // 优惠券 end
  .infoParameter {
    padding: 0px 10px 25px;
    border-bottom: 2px dotted #cac3c3;
    margin-bottom: 25px;
    color: #333;
     font-size: 12px;
    .infoKeys{
      color: #333;
      font-size: 12px;
      width: 66px;
      display: inline-block;
    }
    div{
      float: left;
      width: 50%;
       margin-bottom: 15px;
        &.infoActivity {
         margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    span.infoKey {
      width: 86px;
      text-align: left;
      display: inline-block;
      color: #333;
      font-size: 14px;
    }
    // .el-col {
    //   margin-bottom: 15px;
    //   &.infoActivity {
    //     margin-bottom: 12px;
    //   }
    //   &:last-child {
    //     margin-bottom: 0;
    //   }
    // }
  }
  .UlBoxs{
    line-height: 20px;
    margin-bottom:15px;
    margin-top:15px;
      margin-bottom:15px;
      display: flex;  
  flex-direction: row;  
  justify-content: space-between;
  .el-divider--vertical{
    margin: 3px 8px;
    background: #333333FF;
  }
      .liBox{
        text-align: center; 
        color: #ff3333;
        line-height: 20px;
        font-size:14px;
        font-weight: bold;
        .price{
          font-family: smallYuan;
        }
        span.infoKey {
          text-align: left;
          display: inline-block;
          color: #666;
          font-size: 14px;
        }
        span.infoKeyPrice{
          text-align: left;
          display: inline-block;
          color: #333;
          font-size: 14px;
        }
        del{
          padding-left:5px;
          font-size:12px;
          color: #999;
        }
      }
 }
  .infoBox {
    background: #FFF8F3;
    padding: 20px 10px 10px 10px;
    font-size: 14px;
    color: #999;
   margin-bottom:25px;
   
    del{
      padding-left:5px;
      font-size:12px;
        color: #333;
      text-decoration: line-through
    }
    .el-col {
      margin-bottom: 25px;
      &.infoActivitys{
        margin-bottom:10px;
        span.infoKey {
          width: 86px;
          line-height:24px;
          text-align: center;
          display: inline-block;
          color: #333;
          font-size:15px;
          font-weight: bold;
        }
      }
      &.infoActivity {
        margin-bottom:6px;
        .ActivityDesBox{
          width:calc( 100% - 86px);
          float: right;
        }
        .noMsg{
          height: 0;
        }
        span.infoKey {
          width: 86px;
          line-height:24px;
          text-align: center;
          display: inline-block;
          color: #333;
          font-size:15px;
          font-weight: bold;
        }
      
      }
    }
    .hotProductTagBox{
      margin-bottom: 15px;
    }
    .hotProductTag{
          cursor: pointer;
          margin: 0;
          border-radius:20px;
          background: #F43C38;
          color: #fff;
          padding: 5px 15px;
          font-size: 15px;
          text-align: center;
          b{ 
              display: inline-block;
              width: 15px;
              height: 15px;
              position: relative;
            img{
              display: inline-block;
              position: absolute;
              top: 2px;
              left: 0;
              width: 15px;
              height: 15px;
            }
           
          }
        }
    span.price {
      // color: #333;
      color: #ff3333;
      // padding-right:10px;
      font-size:16px;
      font-weight: bold;
      b {
        font-size: 12px;
      }
    }
    span.infoKey {
      width: 86px;
      text-align: left;
      display: inline-block;
      color: #333;
      font-size: 14px;
    }
    span.infoTag {
      display: inline-block;
      border: 1px solid #F43C38;
      color: #ff3333;
      background: #F43C3817;
      border-radius: 5px;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      padding:1px 10px;
      margin: 0 10px;
      cursor: pointer;
    }
    span.infoDes {
      display: inline-block;
      color: #666;
      margin-bottom:6px;
      line-height:24px;
      width: 80%;
      float: right;
    }
    .numPriceDes{
      width:calc(100% - 176px);
      float: right;
      color: #666;
      letter-spacing: 1px;
      line-height:24px;
      font-size:13px;
      b{
        text-align: center;
        color: #ff3333;
        font-size:13px;
      }
    }
  }
  .infoActivitysBox{
    padding: 0px 10px;
    margin-bottom: 25px;
    .infoKey{
        line-height: 24px;
        text-align: center;
        display: inline-block;
        color: #333;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 5px;
      }
    .infoActivitys{
      .numPriceDesBtn{
        text-align: center;
        float: left;
        padding:3px 28px  5px;
        border: 1.5px solid #E6E6E6FF;
        background:#F5F5F5FF ;
        border-radius: 5px;
        margin-right: 8px;
        cursor: pointer;
        &.active{
          border: 1.5px solid #FF3333FF;
          background:#FEE6E2FF  ;
          p:nth-child(2){
            color: #FF3333FF;
            font-size: 12px;
          }
        }
        p{
          line-height:16px;
          &:nth-child(1){
            color: #FF3333FF;
            font-size: 14px;
            font-weight: bold;
            &::first-letter {
                font-size:80%;
                font-weight: bolder;
            }
          }
          &:nth-child(2){
            color: #666;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
<script>
import Qs from "qs";
// import {AddCart,DownloadFirstTest} from "api/product.js"
const GetCouponItem = ()=>import("components/common/GetCouponItem.vue");
import {AddCart,DownloadFirstTest,getByGoodsId} from "api/product.js"
import { mapState,mapMutations,mapGetters} from "vuex";
export default {
  name: "GoodsInfo",
  data() {
    return {
      keyNum:-1,
      num:0,
      promotionMap:[],
      drawer: false, // 领取优惠券参数
       //  佣金热销标签显示
        ByGoodsFlag:false,
        ByGoodsTag:'',
        ByGoodsId:'',
        selectedAll:false,
          StoreShow:false,
          StoreList:[],
          checkedIds: [], // 初始选中的值
          numPriceArr:[],
          priceMsg:'',
          NowPriceMSG:''
    };
  },
  props: {
    goodsinfo: {
      type: Object,
    },
  },
  components: {
    GetCouponItem,
  },
  computed: {
    //计算属性可实时更新购物车数量
   CarGoodsNum(){
     return this.$store.getters.CarGoodsNum
   },
    ...mapState({
      goodsnum: state => state.carGoodsNum,
    })
  },
  methods: {
    checkFun(item,index){
        this.keyNum=index
        this.priceMsg=item.price
        this.num=item.num
        this.NowPriceMSG='预计省'+((Number(this.goodsinfo.memberPrice) - Number(this.priceMsg))*this.num).toFixed(2)+'元';
    },
    // 跳转热销商品列表
    hotProductFun(){
      // alert(this.ByGoodsId)
       localStorage.setItem("ByGoodsTag", this.ByGoodsTag);
       localStorage.setItem("ByGoodsId", this.ByGoodsId);
       this.$router.push({name:'ProductType',query:{type:12,ByGoodsTag:this.ByGoodsId}})
    },
    JumpONE_BUYFun(promotionId){
        //  this.goodsinfo.promotionMap.promotionId 
      this.$router.push({
         name: "ONEBUYDetail",
        query: { productId:  this.goodsinfo.id, promotionId:promotionId },
      });
    },
    // 跳限时拼团详情页
    JumpFlashGroupFun(promotionId){
      this.$router.push({ name: "FlashGroupDetail", query: { promotionId:promotionId } });
    },
    // 跳限时抢购详情页
    JumpFlashSaleFun(promotionId){
       this.$router.push({
         name: "ActivityModuleDetail",
         query: { id: promotionId,ActivityType:2},
      });
    },

    handleClose(done) {
        done();
      },
     Download(goodsNum){
        // 显示loading
        this.$loading({ fullscreen: true ,background:'#ffffff45'})
      //  alert(goodsId)
         DownloadFirstTest(goodsNum).then(res=>{
           if(res.data.code==400){
               this.$message({
                    message:res.data.msg,
                    type: 'warning'
                  });
                // 关闭loading
                this.$loading().close();
           }else{
            let href=res.data.data;
              // 关闭loading
            this.$loading().close();
            window.open(href, '_blank', 'toolbar=yes, width=900, height=700')
           }
          
     
        })
    },
     ...mapMutations({
      setCarGoodsNum: "setCarGoodsNum",
    }),
     ...mapGetters({
        typeQuantity:"typeQuantity"
    }),
    handleChange() {
      // value
      this.$nextTick(() => {
        if (this.numPriceArr.length==0) {
          return true
        }
        if(this.num<this.numPriceArr[this.numPriceArr.length-1].num){
          this.priceMsg=Number(this.numPriceArr[this.numPriceArr.length - 1].price).toFixed(2)+'起'
          this.keyNum=-1
          // this.NowPriceMSG='预计省'+(Number(this.goodsinfo.memberPrice-Number(this.numPriceArr[this.numPriceArr.length - 1].price).toFixed(2))*this.num).toFixed(2)+'元'
          this.NowPriceMSG=''
        }else{
          for(var index = 0; index < this.numPriceArr.length; index++) {
              if(this.numPriceArr[index].num<=this.num){
                this.keyNum=index;
                this.priceMsg= this.numPriceArr[index].price;
                this.NowPriceMSG='预计省'+((Number(this.goodsinfo.memberPrice) - Number(this.priceMsg))*this.num).toFixed(2)+'元';
                return true
              }
          }
          // this.numPriceArr.forEach((item,index)=>{
          //   if(this.num>=item.num){
          //       this.keyNum=index
          //       this.priceMsg=item.price
          //     }
          // })
        }
      })

    },
   addCartFun(data) {
       if(this.goodsinfo.price==="***"){
          this.$message({
              message:'请点击顶部右侧【手机商城】，扫码二维码下载商城App，上传认证信息',
              type:"warning"
          })
          return false
      }
      // {"isRecentExpiration":0,"quantity":10,"productId":1}
      const addcartDate=[];
      let memberStoreId=Qs.parse(sessionStorage.getItem("memberData")).memberStoreId
      addcartDate.push({
        isRecentExpiration:data.isRecentExpiration,
        quantity:this.num,
        productId:data.id,
        goodNum:data.goodNum,
         memberStoreId:memberStoreId
      })
      // 加入购物车接口
      AddCart(addcartDate).then((data)=>{
        if(data.data.code==200){
            // 提示信息
            this.$notify({
              title: "加入购物车",
              message: "成功",
              type: "success",
              duration: 1000,
            });

            let count=this.num
            this.setCarGoodsNum(count);
        }else{
          this.$notify({
              title: "提示信息",
              message: data.data.msg,
              type: "error",
              duration: 1000,
            });
        }
      })
    },
    immediateBuy:function(data) {

       if(this.goodsinfo.price==="***"){
          this.$message({
              message:'未认证用户不能购买',
              type:"warning"
          })
          return false
      }
      const addcartDate=[];
      addcartDate.push({
        isRecentExpiration:data.isRecentExpiration,
        quantity:this.num,
        productId:data.id,
        goodNum:data.goodNum,
      })
      // 加入购物车接口
      AddCart(addcartDate).then((data)=>{
        if(data.data.code==200){
            // 提示信息
            this.$notify({
              title: "加入购物车",
              message: "成功",
              type: "success",
              duration: 1000,
            });

            let count=this.num
            this.setCarGoodsNum(count);
            this.$router.push({ name: "Cart" });

        }else{
          this.$notify({
              title: "提示信息",
              message: data.data.msg,
              type: "error",
              duration: 1000,
            });
        }
      })
    }
  },
  mounted() {
  },
  watch: {
    goodsinfo: function() {
    },
  },
  created() {
    if(this.goodsinfo.numPrice && this.goodsinfo.numPrice!=''){
      this.numPriceArr=JSON.parse(this.goodsinfo.numPrice)
        // 倒序排序
      this.numPriceArr.sort(function(a, b) {  
        return b.num - a.num;  
      });  
      // this.priceMsg=Number(this.numPriceArr[this.numPriceArr.length - 1].price).toFixed(2)+'起'
      this.priceMsg=Number(this.numPriceArr[0].price).toFixed(2)+'起'
      // this.NowPriceMSG='预计省'+((Number(this.goodsinfo.memberPrice) - Number(this.numPriceArr[0].price))*this.goodsinfo.minOrderNum).toFixed(2)+'元';
      if(this.numPriceArr[this.numPriceArr.length-1].num==this.goodsinfo.minOrderNum){
        this.keyNum=this.numPriceArr.length-1
        this.priceMsg=this.numPriceArr[this.numPriceArr.length-1].price
        this.NowPriceMSG='预计省'+((Number(this.goodsinfo.memberPrice) - Number(this.numPriceArr[this.numPriceArr.length-1].price))*this.goodsinfo.minOrderNum).toFixed(2)+'元';
      }
    }




    this.num=this.goodsinfo.minOrderNum
    // this.promotionMap=this.goodsinfo.promotionMap
    let arr=[];
    this.goodsinfo.promotionMap.forEach(item => {
      if(item.type!='特价'){
           arr.push(item)
         }
    });
    this.promotionMap=arr

    // 传入goodsId获取是否设置 佣金热销标签
    // console.log('aaaa',this.goodsinfo)
    getByGoodsId(this.goodsinfo.id).then(res=>{
      // alert(789)
      console.log(res,"123456")
      if (res.status === 400) {
        this.ByGoodsFlag=false
      }else{
        this.ByGoodsFlag=true
        this.ByGoodsTag=res.data.data.categroyName
        this.ByGoodsId=res.data.data.categroyId
      }
    })
  
  },
};
</script>
